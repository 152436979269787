import {getTenantDomain} from 'common/HelperFunctions'
import {
  GET_ALL_TENANT_STATS,
  GET_ALL_TENANTS,
  GET_ONLINE_USER_STATS,
} from './ActionTypes/ApiActionTypes'

/**
 * @typedef {Object} TenantRequestParams
 * @property {string} apiKey
 * @property {(res?: any) => void} [onSuccess]
 * @property {(err?: any) => void} [onFailure]
 * @property {string} tenantName
 * @property {string} [queryString]
 */

/**
 * @typedef {Object} RequestParams
 * @property {string} apiKey
 * @property {(res?: any) => void} [onSuccess]
 * @property {(err?: any) => void} [onFailure]
 * @property {string} [queryString]
 */

/**
 * @param {TenantRequestParams} param
 * @returns
 */
export const getInstituteOnlineUserStats = ({
  apiKey,
  onSuccess,
  onFailure,
  tenantName,
  queryString = '',
}) => {
  const header2 = {
    'x-api-key': apiKey,
  }

  if (tenantName) {
    header2.domain = getTenantDomain(tenantName)
  }

  return {
    type: GET_ONLINE_USER_STATS,
    payload: {
      apiUrl: 'school/stats/online-users',
      getString: queryString || '',
      useBaseURL: tenantName !== 'lgs',
      useLgsURL: tenantName === 'lgs',
      requestType: 'GET',
      header: 'application/json',
      header2,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

/**
 * @param {object} param
 * @param {string|null} param.tenantName
 * @param {'hourly' | 'daily' | 'weekly' | 'monthly'} param.interval
 * @param {string} param.apiKey
 * @param {string} param.start_date
 * @param {string} param.end_date
 * @param {(res?: any) => void} [param.onSuccess]
 * @param {(err?: any) => void} [param.onFailure]
 * @returns
 */
export const getActiveActiveUserStats = ({
  apiKey,
  tenantName,
  interval,
  start_date,
  end_date,
  onSuccess,
  onFailure,
}) => {
  const apiUrl = `school/stats/active-users${
    !tenantName || tenantName === 'all' ? '/all-tenants' : ''
  }/${interval}`

  const header2 = {
    'x-api-key': apiKey,
  }

  if (tenantName && tenantName !== 'all') {
    header2.domain = getTenantDomain(tenantName)
  }

  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl,
      useBaseURL: tenantName !== 'lgs',
      useLgsURL: tenantName === 'lgs',
      body: {
        start_date,
        end_date,
      },
      getString: '',
      requestType: 'POST',
      header: 'application/json',
      header2,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

/**
 * @param {object} param
 * @param {string|null} param.tenantName
 * @param {'daily' | 'monthly'} param.interval
 * @param {string} param.apiKey
 * @param {string} param.start_date
 * @param {string} param.end_date
 * @param {(res?: any) => void} [param.onSuccess]
 * @param {(err?: any) => void} [param.onFailure]
 * @returns
 */
export const getRequestsStats = ({
  apiKey,
  tenantName,
  interval,
  start_date,
  end_date,
  onSuccess,
  onFailure,
}) => {
  const apiUrl = `school/stats/requests${
    !tenantName || tenantName === 'all' ? '/all-tenants' : ''
  }/${interval}`

  const header2 = {
    'x-api-key': apiKey,
  }

  if (tenantName && tenantName !== 'all') {
    header2.domain = getTenantDomain(tenantName)
  }

  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl,
      useBaseURL: tenantName !== 'lgs',
      useLgsURL: tenantName === 'lgs',
      body: {
        start_date,
        end_date,
      },
      getString: '',
      requestType: 'POST',
      header: 'application/json',
      header2,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

/**
 * @param {RequestParams} param
 * @returns
 */
export const getAllTenantOnlineUserStats = ({apiKey, onSuccess, onFailure, queryString = ''}) => {
  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl: 'school/stats/online-users/all-tenants',
      getString: queryString || '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const calculateAllTenantStats = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl: 'school/stats/calculate-all',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getAllTenantStatsProgress = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl: 'school/stats/calculate-all/progress',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getAllTenants = (apiKey, body, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANTS,
    payload: {
      apiUrl: 'school/stats/tenants',
      getString: '',
      body,
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getAllTenantStatsCalculatedAt = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANTS,
    payload: {
      apiUrl: 'school/stats/all-tenants-calculated-at',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}
